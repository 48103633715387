<section class="user-types">
    <div class="inner">
        <h1>Find the <span>perfect plan</span> for your business.</h1>
        <ul class="user-types">
            <li>
                <header>
                    <h2>Trial</h2>
                </header>
                <article>
                    <ul>
                        <li>2 Uses</li>
                    </ul>
                    <p class="info">Not for commercial use</p>
                </article>
                <footer class="button-container">
                  <p>{{userCanTrial}}</p>
                    <button (click)="getTrialLink()" *ngIf="!userLoggedIn" class="primary">Register with<br>a free trial</button>
                    <button (click)="getTrial()" *ngIf="userLoggedIn && userCanTrial" class="primary">Get a free trial</button>
                    <button *ngIf="userLoggedIn && !userCanTrial" class="primary" disabled="true">You have used Trial on this product</button>
                </footer>
            </li>
			<li>
                <header>
                    <h2>Usage</h2>
                </header>
                <article>
                    <ul>
                        <li>Limited Uses</li>
                        <li>Cloud Based</li>
                    </ul>
                </article>
                <footer class="button-container">
                    <button (click)="getQuote('UsageLicense')" class="primary">Get a Quote</button>
                </footer>
            </li>
            <li>
                <header>
                    <h2>Project</h2>
                </header>
                <article>
                    <ul>
                        <li>Unlimited Uses</li>
                        <li>Flexible Project Duration</li>
						            <li>Limited Users</li>
                        <li>Cloud Based</li>
                    </ul>
                </article>
                <footer class="button-container">
                    <button (click)="getQuote('ProjectLicense')" class="primary">Get a Quote</button>
                </footer>
            </li>
            <li>
                <header>
                    <h2>Enterprise</h2>
                </header>
                <article>
                    <ul>
                        <li>Unlimited Uses</li>
						            <li>Annual Subscription</li>
                        <li>Unlimited Users</li>
                        <li>On Premises or Cloud Based</li>
                    </ul>
                </article>
                <footer class="button-container">
                    <button (click)="getQuote('EnterpriseLicense')" class="primary">Get a Quote</button>
                </footer>
            </li>
        </ul>
    </div>
</section>

<section class="get-demo">
    <div class="inner">
        <p>If you are not sure about which option is best for your, schedule a demo first.</p>
        <!-- <button class="primary" (click)="scheduleDemo()">Schedule a demo</button> -->
        <a role="button" class="primary" href="https://calendly.com/amanda-smartcodeplatform/intro" target="_blank" rel="noopener noreferrer">Contact Us</a>
    </div>
</section>
