<div class="content-wrapper">
  <article class="page-content">
    <header class="our-strategy-banner" role="banner">
      <div class="banner-inner">
        <h1 wmAnimate="landing" speed="normal" delay="500ms">
          <!-- Using REST Generator Effectively: <span class="downloads-product-name">Rethinking Contract-First Development</span> -->
          <span class="smaller">Rethinking Contract-First Development </span> Using REST Generator Effectively
        </h1>
      </div>
    </header>
    <main>
      <section class="blue-bg-summary">
        <div class="section-inner">
          <p class="intro">REST Generator is a powerful tool designed to automate API code generation by leveraging the schema from your target system. Unlike traditional contract-first approaches that begin with an API specification, REST Generator follows a schema-driven approach to provide deeper insight into the system it integrates with. This methodology is essential for generating comprehensive boilerplate code efficiently.</p>

<h2>Key Principles for Effective Use</h2>

<h3>1. Shift from Contract-First to Schema-Driven</h3>
<p>REST Generator generates API code based on the target system's schema rather than a predefined API contract. This schema-driven development approach:</p>
<ul>
    <li>Captures the system’s nuances directly from its data structures.</li>
    <li>Enables the API to reflect a comprehensive understanding of the system it integrates with.</li>
</ul>

<h3>2. Provide Comprehensive Schema Information</h3>
<p>To maximize automation, ensure you upload a complete schema, including all request and response definitions and any associated examples. If the main schema uses includes, upload all related files by zipping them and uploading the zip file.</p>

<h3>3. API Specification Generation</h3>
<p>Unlike traditional contract-first approaches where the specification is manually defined before development begins, REST Generator streamlines this process by generating the API specification dynamically based on the schema and selected endpoints. This ensures that the specification still guides development but in a more automated and schema-driven way.</p>
<ul>
    <li>Automated API Specification Creation:<br>
        REST Generator generates an API specification directly from the schema’s request and response fields, automating the process based on the endpoints selected for integration. <br>
        <span style="text-indent: 1em;">👉 Pro Tip: For MuleSoft customers, zip the API folder, import it into Anypoint Design Center, and publish.</span></li>
    <li>Custom API Specification Option:<br>
        REST Generator allows you to define API specifications based on your organization's canonical models. You can select custom field names that align with your internal data standards and map them to the schema. Provide JSON-formatted mapping instructions to specify how your defined field names correspond to the schema structure following the guidelines in the <a target="_blank" [href]="resources['faq'].link" [title]="resources['faq'].altText" [attr.aria-label]="resources['faq'].altText">FAQ</a>.</li>
</ul>

<h3>4. Explore the Target Schema and Select Endpoints</h3>
<ul>
    <li>Use REST Generator’s Swagger UI schema exploration feature to visualize and interact with the target schema.</li>
    <li>Select only the endpoints you need to integrate with, making your integration lean and efficient. By reloading the schema from memory, you can create microservices quickly and effectively.</li>
</ul>

<h2>Practical Tips for Success</h2>
<ul>
    <li>Adopt a Schema-First Mindset: Embrace schema-driven development for deeper system insights and increased flexibility.</li>
    <li>Be Thorough: Providing comprehensive schema data reduces manual work and ensures better automation.</li>
    <li>Explore and Select Thoughtfully: Leverage Swagger UI for smart endpoint selection.</li>
    <li>Let the Specification Follow: Trust the schema-driven approach to naturally produce or refine API specifications.</li>
</ul>

<p>We are here to help—<a href="https://calendly.com/amanda-smartcodeplatform/intro" target="_blank">book an appointment</a> to navigate Smart Code and unlock the full power of the platform.</p>
        </div>
      </section>
    </main>
  </article>
</div>
