<div class="content-wrapper">
  <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="34.66486mm" height="37.799946mm" viewBox="0 0 34.66486 37.799946" version="1.1" id="svg202" inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)" sodipodi:docname="drawing.svg">
    <defs id="defs196" />
    <metadata id="metadata199"></metadata>
    <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-72.625903,-76.350022)">
        <g id="symbol" transform="matrix(0.26458333,0,0,0.26458333,-90.440296,65.473001)" style="fill:#77a7ff;fill-opacity:1">
            <path class="cls-2" d="M 912.25,389.89 941.76,379 a 63.83,63.83 0 0 0 -3.54,-8 l -27.91,14.46 a 32.71,32.71 0 0 1 1.94,4.43 z" transform="translate(-198.23,-282.89)" id="path159" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 908.9,383 26.53,-16.86 a 62.35,62.35 0 0 0 -3.59,-5.1 l -24.79,19.33 c 0.66,0.85 1.27,1.73 1.85,2.63 z" transform="translate(-198.23,-282.89)" id="path161" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 913,410.32 30.34,8.23 a 65.09,65.09 0 0 0 2.2,-13.41 l -31.39,-1.84 a 33.75,33.75 0 0 1 -1.15,7.02 z" transform="translate(-198.23,-282.89)" id="path163" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 914.16,399.45 31.4,-1.58 A 65.44,65.44 0 0 0 943.62,385 l -30.47,7.81 a 33.48,33.48 0 0 1 1.01,6.64 z" transform="translate(-198.23,-282.89)" id="path165" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 908.26,420.53 25.94,17.77 a 65.84,65.84 0 0 0 7.09,-13.51 L 912,413.43 a 33.75,33.75 0 0 1 -3.74,7.1 z" transform="translate(-198.23,-282.89)" id="path167" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 899.13,429.6 17.34,26.23 A 65.92,65.92 0 0 0 929.8,444 L 906,423.48 a 34.18,34.18 0 0 1 -6.87,6.12 z" transform="translate(-198.23,-282.89)" id="path169" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="M 880.07,435.41 A 34.13,34.13 0 0 1 878,367.22 V 380 l 14.69,-10.44 23.73,-16.86 -20,-14.94 L 878,324 v 11.76 a 65.57,65.57 0 1 0 31,124.4 L 895.16,431.9 a 34.07,34.07 0 0 1 -15.09,3.51 z" transform="translate(-198.23,-282.89)" id="path171" style="fill:#77a7ff;fill-opacity:1" />
        </g>
    </g>
  </svg>

  <article class="page-content">
    <header class="homepage-banner generator-banner" role="banner">
      <div class="banner-inner">
        <h1 class="first" wmAnimate="landing" speed="normal" delay="500ms">REST Generator<span>Smart <strong>Code</strong> Platform</span></h1>
      </div>
    </header>



    <main role="main">

      <section class="generator-home-heading-section">
        <div class="section-inner">
          <h2>Quickly build best in class applications based on the most popular API Specifications.</h2>
          <ul>
              <li>
                <h3>Multi-Language Options</h3>
                <ul>
                    <li>Supports iPaaS and open-source options</li>
                    <li>Easy to migrate from any platform</li>
                    <li>Helps developers shift their skillset quickly</li>
                </ul>
              </li>
              <li>
                <h3>Accelerates Development</h3>
                <ul>
                    <li>Patented code generators</li>
                    <li>Simple to use interface</li>
                    <li>Endpoint selection for legacy systems</li>
                </ul>
              </li>
              <li>
                <h3>Reduces Maintenance Costs</h3>
                <ul>
                    <li>Standardized, robust code</li>
                    <li>Operational-driven design</li>
                    <li>Built-in best practices</li>
                </ul>
              </li>
            </ul>
        </div>

      </section>

	  <section class="headquote-section platform-home-headquote-section">
        <div class="section-inner">
          <h2 once="true" speed="fast" wmAnimate="fadeInUp" aos="0.25">Smart Code developer tools help you save on your integration costs by allowing you to select the best products for your organization, without vendor lock-in.</h2>
			<ul>
				<li>
					<strong>Speed Up Your API Development:</strong> Building and integrating APIs takes time. Traditional methods can delay your projects, leading to missed deadlines and increased costs. Smart Code automates the API integration process, significantly reducing development time. As a result, your migrations can be completed up to 5x faster, allowing you to move quickly and meet business demands.</li>
				<li>
					<strong>Avoid Technical Debt:</strong> Migrating directly from your existing integration code often carries over baggage—outdated, inefficient code that hampers development and drives up long-term maintenance costs. With Smart Code, there’s no need to inherit technical debt. By generating fresh, modern code directly from your system’s schema, we ensure your APIs adhere to best practices, helping you avoid costly issues and future-proof your integration strategy.</li>
				<li>
					<strong>Seamlessly Integrates with Your Development Stack:</strong> Smart Code complements your existing tools by replacing scaffolders and manual templates at the start of the development process. It lays the groundwork with clean, modern code, allowing you to seamlessly transition to low-code or AI-driven tools for further development.</li>
			</ul>
        </div>
      </section>

      <section class="boxes-section">
        <div class="section-inner">

          <ul class="percetage-boxes" #numbersList>
            <li [class.active]="showListItem[0]">
              <div class="circle">
                <lib-counter #counter1 [text]="'lower'" [percent]="80"></lib-counter>
              </div>
              <div class="desc">
                <h3>Replacement Costs</h3>
              </div>
            </li>
            <li [class.active]="showListItem[2]">
              <div class="circle">
                <lib-counter #counter3 [text]="'less'" [percent]="true" [percent]="85"></lib-counter>
              </div>
              <div class="desc">
                <h3>Technical Debt</h3>
              </div>
            </li>
            <li [class.active]="showListItem[1]">
              <div class="circle">
                <lib-counter #counter2 [text]="'faster'" [percent]="73"></lib-counter>
              </div>
              <div class="desc">
                <h3>Delivery</h3>
              </div>
            </li>
          </ul>

        </div>
      </section>


      <section class="more-bottom-margin">
        <div class="section-inner">
          <div class="transparetn-block-columns">
            <div class="transparent-block flipped-bg  style-2-left" once="true" speed="fast" wmAnimate="fadeInUp" delay="250ms" aos="0.25">
              <div class="transparent-block-text single-block">
                <img src="/assets/images/blue-arrow-right.png" class="arrow-image" alt="iPaaS to Client Systems diagram">
                <h2>Create Your APIs at the Click of a Button</h2>
                <p>Accelerate your applications with a few simple configuration selections to create standardized, robust code.</p>
                <!-- <a role="button" [routerLink]="[{ outlets: { sidePopup: ['demo','Platform']}}]" [queryParams]="{'returnUrl': router.url}" class="text-button dark-bg bigger" rel="noopener noreferrer">Request a demo</a> -->
                <a role="button" href="https://calendly.com/amanda-smartcodeplatform/intro" target="_blank" class="text-button dark-bg bigger" rel="noopener noreferrer">Contact Us</a>
              </div>
            </div>
            <div class="transparent-block   style-1-right" once="true" speed="fast" wmAnimate="fadeInUp" delay="250ms" aos="0.25">
              <div class="transparent-block-text space-evenly">
                <h3><strong>Smart Code</strong> Automates Code in MuleSoft and Spring Boot Java.</h3>
                <div style="display: flex; justify-content: start; margin: auto; gap: 20px;">
                  <button (click)="Register()" class="secondary no-bg lighter">Try Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </section>

      <!-- <section>
        <div style="display: flex; justify-content: center;">
          <div class="section-inner">
            <div class="transparent-block" wmAnimate="fadeInUp" once="true" aos="0.8" style="flex-direction: column;">
              <h4>CREATE YOUR APIS AT THE CLICK OF A BUTTON</h4>
              <span>Accelerate your applications with a few simple configuration selections to create standardized, robust code.</span>
              <a role="button" target="_blank" class="text-button dark-bg bigger" rel="noopener noreferrer">
                Request a demo
              </a>
            </div>
          </div>
          <div class="section-inner">
            <div class="transparent-block" wmAnimate="fadeInUp" once="true" aos="0.8" style="flex-direction: column;">
              <h4>SMART CODE AUTOMATES CODE IN MULESOFT AND SPRING BOOT JAVA</h4>
              <span>Accelerate your applications with a few simple configuration selections to create standardized, robust code.</span>
              <button (click)="RegisterWithTrial()" class="primary">TRY NOW</button>
            </div>
          </div>
        </div>
      </section> -->


      <section class="">
        <div class="section-inner">
          <h2 once="true" speed="fast" wmAnimate="fadeInUp" aos="0.25" class="no-bottom-margin">
            Microservices in different languages.<br>Single <span class="blue-underline">operational view</span>.
          </h2>


          <div class="logos-bar" >
            <ul>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/generator-microservices/log-filter-color.png" alt="AWS Logo" class="mouseout-gray">
                    <img src="/assets/images/generator-microservices/log-filter-blue.png" alt="AWS Logo" class="mouseout-gray">
                  </div>
                  <figcaption>Log Filtering</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/generator-microservices/health-monitor-color.png" class="mouseout-gray" alt="Azure Logo">
                    <img src="/assets/images/generator-microservices/health-monitor-blue.png" class="mouseout-gray" alt="Azure Logo">
                  </div>
                  <figcaption>Health Monitoring</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/generator-microservices/security-color.png" class="mouseout-gray" alt="Google Cloud Logo">
                    <img src="/assets/images/generator-microservices/security-blue.png" class="mouseout-gray" alt="Google Cloud Logo">
                  </div>
                  <figcaption>Security and Fraud Detection</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/generator-microservices/usage-analytics-color.png" class="mouseout-gray" alt="Cloud Native Logo">
                    <img src="/assets/images/generator-microservices/usage-analytics-blue.png" class="mouseout-gray" alt="Cloud Native Logo">
                  </div>
                  <figcaption>Client Behavior and Usage Analytics</figcaption>
                </figure>
              </li>
            </ul>





          </div>
        </div>
      </section>

      <!-- <section>
        <div class="section-inner">

          <h2 once="true" speed="fast" wmAnimate="fadeInUp" aos="0.25">
            Generate your development with Smart Code.  Deploy anywhere.
          </h2>
          <div class="transparent-block" style="display: flex; justify-content: space-around;">
            <div style="display: flex; flex-direction: column;">
              <img src="/assets/images/log-filtering.png" alt="Icon 5" style="width: 100px; height: 100px; filter: invert(1) grayscale(1) brightness(0.5);">
              <h6 style="margin-top: 20px; width: 120px">LOG FILTERING</h6>
            </div>
            <div style="display: flex; flex-direction: column;">
              <img src="/assets/images/health-monitoring.png" alt="Icon 4" style="width: 100px; height: 100px; filter: invert(1) grayscale(1) brightness(0.5);">
              <h6 style="margin-top: 20px; width: 120px">HEALTH MONITORING</h6>
            </div>
            <div style="display: flex; flex-direction: column;">
              <img src="/assets/images/security-and-fraud-detection.png" alt="Icon 3" style="width: 100px; height: 100px; filter: invert(1) grayscale(1) brightness(0.7);">
              <h6 style="margin-top: 20px; width: 120px">SECURITY AND FRAUD DETECTION</h6>
            </div>
            <div style="display: flex; flex-direction: column;">
              <img src="/assets/images/client-behavior-usage-analytics.png" alt="Icon 2" style="width: 100px; height: 100px; filter: invert(1) grayscale(1) brightness(1);">
              <h6 style="margin-top: 20px; width: 120px">CLIENT BEHAVIOR AND USAGE ANALYTICS</h6>

            </div>
          </div>
        </div>
      </section> -->

      <section class="more-bottom-margin">
        <div class="section-inner">
          <h2 once="true" speed="fast" wmAnimate="fadeInUp" aos="0.25" class="no-bottom-margin">Start Quickly: <strong>We Will Guide You</strong></h2>

          <div class="transparent-block style-1-left margin-bottom">
            <div>
              <div>
                <h2>Developer Onboarding</h2>
                <h5>Cut down on your training costs and accelerate learning by starting every project with a clear and standardized format. Safeguard your business with built-in best practices to reduce technical debt.</h5>
                <a role="button" style="margin-left: -.5em;" [routerLink]="'/whitepaper-download'" class="text-button lighter narrower no-bg external-link dark-container" rel="noopener noreferrer">Download our whitepaper</a>
              </div>

            </div>


          </div>
        </div>
      </section>

      <!-- <section style="display: flex; justify-content: center;">
        <div style="max-width: 80%;">
          <h1 style="font-size: 2em; margin-bottom: 30px;">
            START QUICKLY: WE GUIDE YOU
          </h1>
          <div style="display: flex; background-color: #0E1621; color: #FFFFFF; padding: 20px;">
            <div style="flex: 1; border-right: 1px solid #B38F82; padding-right: 20px;">
              <h2 style="font-size: 1.5em; margin-bottom: 15px;">DEVELOPER ONBOARDING</h2>
              <div style="display: flex; align-items: center;">
                <p style="flex: 1; font-size: 1em;">
                  Cut down on your training costs and accelerate learning by starting every project with a clear and standardized format.  Safeguard your business with built-in best practices to reduce technical debt.
                </p>
              </div>
              <a href="#" style="color: #B38F82; font-weight: bold; text-decoration: none; display: block; margin-top: 10px;">Explore the product -></a>
            </div>
          </div>
        </div>
      </section> -->

      <!-- <section style="display: flex; justify-content: center;">
        <div class="section-inner transparent-block" style="max-width: 80%; background-color: #000; color: #fff; margin: 0; padding: 20px; display: flex; justify-content: space-between; flex-wrap: wrap;">
            <div style="max-width: 45%;">
                <h1 style="margin: 0;">SAFEGUARD YOUR BUSINESS FROM TECHNICAL DEBT</h1>
                <p style="margin-top: 20px;">The cornerstone to code quality and design is defining a set of best practices and design patterns to be standardized across the enterprise. This provides developers a framework of proven solutions to common challenges to help improve the consistency and quality of the code delivered so that it can be supported and scaled easily.</p>
            </div>

            <div style="max-width: 45%;">
                <div style="font-size: 14px; margin-top: 20px; color: #ccc;">
                    <p>User form fields same as registration from idp.</p>
                    <p>Note Only<br>Need to see if email template can take new category for <span style="color: #ffaf60;">whitepaper</span> (Filip)</p>
                </div>

                <div style="border: 1px solid #ffaf60; padding: 20px; text-align: center; margin-top: 20px;">
                    <p style="margin-bottom: 20px; font-size: 14px;">By registering, you agree to the processing of your personal data by Salesforce as described in the <a href="#" style="color: #ffaf60; text-decoration: none;">Privacy Statement</a>.</p>
                    <button style="background-color: transparent; border: 1px solid #ffaf60; color: #ffaf60; padding: 10px 20px; text-transform: uppercase; cursor: pointer; font-weight: bold;">Download</button>
                    <div style="font-size: 14px; margin-top: 20px; color: #ccc;">
                        <p>Note Only<br>Will provide <span style="color: #ffaf60;">whitepaper</span> have to write it first</p>
                    </div>
                </div>
            </div>
        </div>

      </section> -->





      <section class="blue full-width">
        <div class="section-inner">
          <h3 class="left">We integrate with</h3>

          <ul class="restgen-client-icons">
            <li class="shorter">
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/aws-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/aws-white.png" alt="">
              </div>
            </li>
            <li class="shorter">
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/Salesforce-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/Salesforce-white.png" alt="">
              </div>
            </li>
            <li>
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/sap-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/sap-white.png" alt="">
              </div>
            </li>
            <li>
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/MS-D365-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/MS-D365-white.png" alt="">
              </div>
            </li>
            <li class="shorter">
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/hubspot-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/hubspot-white.png" alt="">
              </div>
            </li>
            <li class="shorter">
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/appdynamics-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/appdynamics-white.png" alt="">
              </div>
            </li>
            <li>
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/JackHenry-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/JackHenry-white.png" alt="">
              </div>
            </li>
            <li>
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/Fiserv-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/Fiserv-white.png" alt="">
              </div>
            </li>
            <li>
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/amdocs-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/amdocs-white.png" alt="">
              </div>
            </li>
            <li>
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/NetSuite-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/NetSuite-white.png" alt="">
              </div>
            </li>
            <li>
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/procore-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/procore-white.png" alt="">
              </div>
            </li>
            <!-- <li>
              <div class="img-container">
                <img class="clients-icon" src="/assets/images/generator-integrate/Analytics-color.png" alt="">
                <img class="clients-icon" src="/assets/images/generator-integrate/Analytics-white.png" alt="">
              </div>
            </li> -->
          </ul>


          <h3 class="right">and many more.</h3>
        </div>


        <!-- <div style="display: flex; flex-direction: column">
          <h5 style="text-align: center">WE INTEGRATE WITH</h5>
          <div style="
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 20px;
            padding: 40px 20px;
            text-align: center;
            background-color: #2c4e8a;">

            <img src="/assets/images/aws-logo.png" alt="AWS" style="max-width: 100px; height: auto; margin: 0 auto;">
            <img src="/assets/images/salesforce-logo.png" alt="Salesforce" style="max-width: 100px; height: auto; margin: 0 auto;">
            <img src="/assets/images/sap-logo.jpg" alt="SAP" style="max-width: 100px; height: auto; margin: 0 auto;">
            <img src="/assets/images/microsoft-logo.png" alt="Microsoft Dynamics 365" style="max-width: 100px; height: auto; margin: 0 auto;">
            <img src="/assets/images/hubspot-logo.png" alt="HubSpot" style="max-width: 100px; height: auto; margin: 0 auto;">
            <img src="/assets/images/jack-henry-logo.png" alt="Jack Henry" style="max-width: 100px; height: auto; margin: 0 auto;">
            <img src="/assets/images/fiserv-logo.png" alt="Fiserv" style="max-width: 100px; height: auto; margin: 0 auto;">
            <img src="/assets/images/amdocs-logo.png" alt="Amdocs" style="max-width: 100px; height: auto; margin: 0 auto;">
            <img src="/assets/images/netsuite-logo.png" alt="NetSuite" style="max-width: 100px; height: auto; margin: 0 auto;">
            <img src="/assets/images/procore-logo.png" alt="Procore" style="max-width: 100px; height: auto; margin: 0 auto;">
            </div>
        </div> -->

      </section>
    </main>
  </article>
</div>
