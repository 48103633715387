import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { NgNavigation } from 'projects/nine-gold-lib/src/lib/shared/ng-navigation/ng-navigation';

@Component({
  selector: 'app-generator-instructions',
  templateUrl: './generator-instructions.component.html',
  styleUrls: ['./generator-instructions.component.scss']
})
export class GeneratorInstructionsComponent implements OnInit {
  resources: any;

  constructor(private settings: AppSettingsService) { }

  ngOnInit(): void {
    let res:NgNavigation[] = this.settings.getResourcesLinks();
    this.resources = {};
    res.forEach(r => {
      this.resources[r.name] = r;
    });
  }

}
