import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneratorComponent } from './application/generator/generator-page/generator.component';
import { DownloadsComponent } from './application/downloads/downloads-page/downloads.component';
import { AuthGuard } from 'projects/nine-gold-lib/src/lib/services/auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { QuoteFormComponent } from './pages/quote-form/quote-form.component';
// import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { DownloadsSidebarComponent } from './application/downloads/downloads-sidebar/downloads-sidebar.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { DemoFormComponent } from './pages/demo-form/demo-form.component';
import { SigninRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signout-redirect-callback.component';
import { NewAuthGuard } from 'projects/nine-gold-lib/src/lib/services/newauth.guard';
import { CanDeactivateGuard } from 'projects/nine-gold-lib/src/lib/services/guards/can-deactivate.guard';
import { SupportRequestComponent } from './pages/support-request/support-request.component';
import { RegisterCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/register-callback.component';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { WhitepaperDownloadComponent } from './pages/whitepaper-download/whitepaper-download.component';
import { WhitepaperConfirmComponent } from './pages/whitepaper-confirm/whitepaper-confirm.component';
import { GeneratorInstructionsComponent } from './pages/generator-instructions/generator-instructions.component';


const routes: Routes = [
  { path: 'generator', component: GeneratorComponent, canActivate: [AuthGuard] },
  { path: 'downloads', component: DownloadsComponent, canActivate: [AuthGuard] },
  { path: 'quote/:id/:action/:licenseType', component: QuoteFormComponent, outlet: 'sidePopup',canDeactivate: [CanDeactivateGuard]},
  { path: 'quote/:id/:action', component: QuoteFormComponent, outlet: 'sidePopup',canDeactivate: [CanDeactivateGuard]},
  { path: 'supportrequest', component: SupportRequestComponent, outlet: 'sidePopup',canDeactivate: [CanDeactivateGuard]},
  { path: 'demo/:productName', component: DemoFormComponent, outlet: 'sidePopup'},
  { path: 'documentation/:cacheKey/:documentKey', component: DocumentationComponent, outlet: 'sidePopup'},
  { path: 'generator-instructions', component: GeneratorInstructionsComponent},
  { path: 'pricing', component: PricingComponent},
  { path: 'whitepaper-download', component: WhitepaperDownloadComponent},
  { path: 'whitepaper-confirm', component: WhitepaperConfirmComponent},
  { path: 'home', component: HomeComponent},

  // { path: 'app', component: AppLayoutComponent, canActivate: [AuthGuard], children: [
  //   { path: 'generator', component: GeneratorComponent, canActivate: [AuthGuard] },
  //   { path: 'downloads', component: DownloadsComponent, canActivate: [AuthGuard] },
  //   { path: '', redirectTo: 'generator', pathMatch: 'full' }
  // ]},

  { path: 'app', loadChildren:  () => (import('./application/generator-app.module').then(m => m.GeneratorAppModule))  },

  // { path: 'app', component: AppLayoutComponent, canActivate: [NewAuthGuard], children: [
  //   { path: 'generator', component: GeneratorComponent, canActivate: [NewAuthGuard] },
  //   { path: 'downloads', component: DownloadsComponent, canActivate: [NewAuthGuard] },
  //   { path: '', redirectTo: 'generator', pathMatch: 'full' }
  // ]},

  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
  { path: 'register-callback-trial', component: RegisterCallbackComponent, data: {layout: 'auth'} },

  { path: '', component: HomeComponent },
  // { path: '', redirectTo: '/generator', pathMatch: 'full' },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
